/* eslint-disable react/prop-types */
import React from 'react'
import Enquiry from 'routes/contact/enquiry'

// Meta Component
// import Meta from 'components/Meta/Meta'

// Pricing Route
export default Enquiry

// Gatsby SEO Head
export function Head() {
  return <title>Enquiry | Pocketworks</title>
}
