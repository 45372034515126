import React from 'react'
import { Section, Container } from 'react-bulma-components'

function AboutProject() {
  return (
    <Section>
      <Container className="content has-text-centered">
        <h2>
          Tell us about <span className="has-text-success">your project</span>
        </h2>
        <iframe
          src="https://pipedrivewebforms.com/form/c7883e4880ae451054fdb15e9f07dd5c1730301"
          style={{ width: '100%', height: '1000px' }}
          title="Pipe Drive Web Form"
        />
      </Container>
    </Section>
  )
}

export default AboutProject
