import React from 'react'

// Components
import SiteNav from 'components/navbar'
import AboutProject from './components/aboutProject'
import ContactNavLinks from './components/contactNavLinks'

function Enquiry() {
  // if (!data) return <div>No Data ...</div>
  return (
    <div>
      <SiteNav fullLogo />
      <ContactNavLinks activeLink="enquiry" />
      <AboutProject />
    </div>
  )
}

export default Enquiry
